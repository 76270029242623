body {
    > iframe {
        display: none !important;
    }
}

header {
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05) !important;
}


.full-width{
    width: 100%;
}

.mb-5 {
    margin-bottom: 5px;
}

.toolbar-wrapper {
    background-color: rgba(237, 237, 237, 0.35);
}

.filters-wrapper {
    background-color: transparent;
}


.filter-btn{
    padding: 14px 20px;
}

.shadow {
    -webkit-box-shadow: 0px 0px 5px 3px rgba(0,0,0,0.1);
    -moz-box-shadow: 0px 0px 5px 3px rgba(0,0,0,0.1);
    box-shadow: 0px 0px 5px 3px rgba(0,0,0,0.1);
    border-radius: 6px;
}

.media-item-accordion {
    -webkit-box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.1) !important;
    -moz-box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.1) !important;
    box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.1) !important;
    margin-bottom: 4px;
}

.media-item-content {
    background-color: rgba(237, 237, 237, 0.35);
    padding: 25px 20px;
}


.ReactModalPortal {
    position: relative;
    z-index: 9999;
}

.scrollable-content{
    overflow-y:auto;
    height:calc(100vh - 335px);

    &.no-tabs {
        height:calc(100vh - 285px);
    }

}

.MuiPaper-root.MuiPopover-paper, .MuiAutocomplete-popper{
    box-shadow: 1px 1px 4px 1px rgb(0 0 0 / 10%) !important;
    max-height: 400px;
}
.MuiPaper-root.MuiPaper-elevation{box-shadow: 1px 1px 4px 1px rgb(0 0 0 / 10%) !important;}
.MuiChip-filled{
    height: 27px;
}

.small-cell {
    width: 60px
}

.click-row{cursor: pointer;}

.tabs-wrapper{
    display: inline-grid;
}

.last-th{width: 110px;}

.margin-right-btn{margin-right: 10px;}

.filters-wrapper.MuiPaper-elevation{
    box-shadow: none !important;
    &.Mui-expanded{
        padding-bottom: 20px;
    }
}

.lang-nav{
    margin-top: 2px !important; border:1px solid #e0e0e0 !important; margin-left: 8px;
    &.active{background: #e0e0e0 !important;}
    .MuiChip-avatar{width: 16px !important; height: 16px !important;}
}

.read-only{
    .quill{opacity: 0.6; cursor: inherit;}
}

.ril-image-current{background: #fff;}

.preloader{
    justify-content: center;
    align-items: center;
    display: flex;
    z-index: 10000;
    top: 0;
    > span{
        position: absolute; top:50%; left:50%; z-index: 20;
    }
    &:before{
        content:""; width: 100%; height: 100%; background: #000; opacity: 0.1; z-index: 10; position: absolute; top:0;
    }

}

.related-item{
    max-width: 100%; display: block !important;
    .item{
       margin: 0 4px 4px 0;
    }
}

::-webkit-scrollbar {
width: 6px;
}

::-webkit-scrollbar-button {
background: #e3e3e3;
display: none;
}

::-webkit-scrollbar-thumb {
background: #233044;
border-radius: 8px;
}

::-webkit-scrollbar-thumb:hover {
background: #233044;
}

::-webkit-scrollbar-track {
background: #e3e3e3;
}

::-webkit-scrollbar-corner {
background: #ddd;
border-radius: 5px;
}
